import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { UUID, WrapNumbPrefix, GenerateNumberRange, Random } from '@mini-code/base-func/generation';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "generation--数据产出"
    }}>{`Generation / 数据产出`}</h1>
    <p>{`basic-helper 提供一些常用的数据生成器`}</p>
    <h2 {...{
      "id": "uuid"
    }}>{`UUID`}</h2>
    <p>{`由时间戳 + (1 ～ 10000的随机数)生成的 ID，可以应付一般的前端应用的随机唯一 ID 需求`}</p>
    <Playground __position={0} __code={'() => {\n  const [ID, setID] = useState(UUID())\n  return (\n    <div>\n      <div>ID: {ID}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setID(UUID())\n        }}\n      >\n        生成默认 9 位数的 ID\n      </span>\n      <span\n        className=\"btn red mr10\"\n        onClick={e => {\n          setID(UUID(5))\n        }}\n      >\n        生成 5 位数的 ID\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      UUID,
      WrapNumbPrefix,
      GenerateNumberRange,
      Random
    }} mdxType="Playground">
  {() => {
        const [ID, setID] = useState(UUID());
        return <div>
          <div>ID: {ID}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            setID(UUID());
          }}>生成默认 9 位数的 ID</span>
          <span className="btn red mr10" onClick={e => {
            setID(UUID(5));
          }}>生成 5 位数的 ID</span>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "为--10-数字添加-0-前缀"
    }}>{`为 < 10 数字添加 0 前缀`}</h2>
    <Playground __position={1} __code={'() => {\n  const [numb, setState] = useState(WrapNumbPrefix(1, true))\n  return (\n    <div>\n      <div>numb: {numb}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        onChange={e => {\n          const val = e.target.value\n          setState(WrapNumbPrefix(val, true))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      UUID,
      WrapNumbPrefix,
      GenerateNumberRange,
      Random
    }} mdxType="Playground">
  {() => {
        const [numb, setState] = useState(WrapNumbPrefix(1, true));
        return <div>
          <div>numb: {numb}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} onChange={e => {
            const val = e.target.value;
            setState(WrapNumbPrefix(val, true));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "生成指定范围之内的随机数"
    }}>{`生成指定范围之内的随机数`}</h2>
    <Playground __position={2} __code={'() => {\n  const range = [1, 11]\n  const [numb, setState] = useState(Random(range))\n  return (\n    <div>\n      <div>\n        生成范围 {JSON.stringify(range)} 之内的随机数: {numb}\n      </div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setState(Random(range))\n        }}\n      >\n        生成随机数\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      UUID,
      WrapNumbPrefix,
      GenerateNumberRange,
      Random
    }} mdxType="Playground">
  {() => {
        const range = [1, 11];
        const [numb, setState] = useState(Random(range));
        return <div>
          <div>生成范围 {JSON.stringify(range)} 之内的随机数: {numb}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            setState(Random(range));
          }}>生成随机数</span>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      